







































import Vue from 'vue';
import lazyLoad from '@/utils/lazyLoad';

export default Vue.extend({
  name: 'A2HS',
  components: {
    Logo: lazyLoad('components/Shared/Logo'),
  },
  data() {
    return {
      deferredPrompt: null,
      installPrompt: false,
    };
  },
  created() {
    window.addEventListener('beforeinstallprompt', (event) => {
      // supress browser's default prompt
      event.preventDefault();
      // stash the event so it can be triggered later
      this.deferredPrompt = event;
      // open the install banner
      this.installPrompt = true;
    });

    window.addEventListener('appinstalled', () => {
      // listen for the event after the app has been installed
      // needed in case the user installs your PWA from the address bar or other browser component
      // close the install banner
      this.installPrompt = false;
      // Clear the deferredPrompt so it can be garbage collected
      this.deferredPrompt = null;
      // Optionally, send analytics event to indicate successful install
      console.log('PWA was installed');
    });
  },
  methods: {
    async addToHomeScreen() {
      this.deferredPrompt.prompt();

      this.installPrompt = false;
      const { outcome } = await this.deferredPrompt.userChoice;
      // reset deferredPrompt event if the App has been installed
      if (outcome === 'accepted') this.deferredPrompt = null;
    },
  },
});
